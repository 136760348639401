import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/headerComponent';
import BannerLeadComponent from '../components/landingComponents/bannerLEadComponent';
import HeaderComponentLeader from '../components/landingComponents/headerComponentLeader';
import NewFooterComponent from '../components/newFooterComponent';
import MissingComponent from '../components/landingComponents/missingComponent';
import CompanyStaticComponent from '../components/landingComponents/companyStaticComponent';
import RequestADemoComponent from '../components/landingComponents/requestADemoComponent';
import DemoImage from '../images/requestADemo.png';
import Layout from '../components/layout';

const ChangeManagmentLandingPage = props => {
  const {
    data: {
      wpPost: {
        seo,
        agreedHelpSection: {
          agreedHelpSectionTitle,
          agreedHelpSectionDescription,
          agreedHelpSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: AgreedHelpSectionImage },
            },
          },
        },
        articleHomeSection: {
          articleHomeTitle,
          articleHomeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ArticleHomeImage },
            },
          },
        },
        agreedHelpSavingTimeSection: {
          savingTimeSectionDescription,
          savingTimeSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: SavingTimeSectionImage },
            },
          },
        },
        beAmazedByWhatYourPeopleProduceSection: {
          beAmazedByWhatYourPeopleProduceSectionDescription,
          beAmazedByWhatYourPeopleProduceSectionImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: BeAmazedByWhatYourPeopleProduceSectionImage,
              },
            },
          },
        },
        landingPageLeadSection: {
          leadSubtitle,
          leadImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: LeadImage },
            },
          },
        },
        landingPageListSection: {
          landingPageListText,
          landingPageListImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: LandingPageListImage },
            },
          },
        },
        newHomeCompaniesSection: {
          companiesSectionDescription,
          companiesSectionTitle,
          companiesSectionBankOfAmerica: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: companiesSectionBankOfAmerica,
              },
            },
          },
          companiesSectionFanniemae: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionFanniemae },
            },
          },
          companiesSectionHeinz: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionHeinz },
            },
          },
          companiesSectionHumana: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionHumana },
            },
          },
          companiesSectionMufg: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionMufg },
            },
          },
          companiesSectionNyse: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionNyse },
            },
          },
          companiesSectionPanasonic: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionPanasonic },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  console.log('1', seo);
  console.log('2', title);

  return (
    <Layout seo={seo} title={title}>
      <Header />
      <HeaderComponentLeader
        teamworkTitle={agreedHelpSectionTitle}
        teamworkDescription={agreedHelpSectionDescription}
        landingImage={AgreedHelpSectionImage}
        isLanding={true}
        isLandingPage={true}
      />
      <BannerLeadComponent isLead={false} />
      <MissingComponent
        helpDescription={articleHomeTitle}
        agreedScreenshots={ArticleHomeImage}
        isFirst={true}
        isSecond={false}
        isLandingPage={true}
        isLead={true}
      />
      <MissingComponent
        helpDescription={savingTimeSectionDescription}
        agreedScreenshots={SavingTimeSectionImage}
        isFirst={false}
        isSecond={true}
        isLandingPage={true}
        isLead={false}
        SI={true}
      />
      <MissingComponent
        helpDescription={beAmazedByWhatYourPeopleProduceSectionDescription}
        agreedScreenshots={BeAmazedByWhatYourPeopleProduceSectionImage}
        isFirst={true}
        isSecond={false}
        isLandingPage={true}
        isLead={false}
        SI={true}
      />
      <MissingComponent
        helpDescription={leadSubtitle}
        agreedScreenshots={LeadImage}
        isFirst={false}
        isSecond={true}
        isLandingPage={true}
        isLead={false}
        SI={true}
      />
      <MissingComponent
        helpDescription={landingPageListText}
        agreedScreenshots={LandingPageListImage}
        isFirst={true}
        isSecond={false}
        isLandingPage={true}
        isLead={true}
        SI={false}
      />
      <CompanyStaticComponent
        companiesTitle={companiesSectionTitle}
        companiesDescription={companiesSectionDescription}
        panasonicImage={companiesSectionFanniemae}
        humanImage={companiesSectionHumana}
        bankOfAmericaImage={companiesSectionNyse}
        mellonImage={companiesSectionPanasonic}
        mufgImage={companiesSectionHeinz}
        nyseImage={companiesSectionBankOfAmerica}
        pncImage={companiesSectionMufg}
        isSI={true}
        isLead={true}
      />
      <RequestADemoComponent
        isLanding={true}
        requestDemoTitle="Schedule a Demo"
        RequestADemoImage={DemoImage}
        SI={true}
        // isDEILandingPage={true}
      />
      <NewFooterComponent isProduct={true} />
    </Layout>
  );
};

export default ChangeManagmentLandingPage;

export const query = graphql`
  query MyQuery {
    wpPost(slug: { eq: "change-managment" }) {
      seo {
        metaDesc
        title
      }
      agreedHelpSection {
        agreedHelpSectionTitle
        agreedHelpSectionDescription
        agreedHelpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleHomeSection {
        articleHomeTitle
        articleHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSavingTimeSection {
        savingTimeSectionDescription
        savingTimeSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      beAmazedByWhatYourPeopleProduceSection {
        beAmazedByWhatYourPeopleProduceSectionDescription
        beAmazedByWhatYourPeopleProduceSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      landingPageLeadSection {
        leadSubtitle
        leadImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      landingPageListSection {
        landingPageListText
        landingPageListImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      newHomeCompaniesSection {
        companiesSectionTitle
        companiesSectionDescription
        companiesSectionBankOfAmerica {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionFanniemae {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionHeinz {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionHumana {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionMufg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionNyse {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionPanasonic {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
